// export let computTime = (num) => {
//   return num / 3 + "分钟";
// };
//全部导出处理表头函数
//插件地址：https://blog.csdn.net/qq_42313028/article/details/103069876
// export let chuliTitle = (arr) => {
//   let obj = {};
//   arr.forEach((item) => {
//     obj[item.title] = item.dataIndex;
//   });
//   return obj;
// };
//首页处理菜单返回刷新问题
export function handleMenu(arrlist, url) {
  // console.log(arrlist)
  let obj = {};
  function getUrl(arrlist, url) {
    try {
      arrlist.forEach((item) => {
        if (item.items.length !== 0) {
          getUrl(item.items, url);
        } else {
          if (item.url.indexOf(url) !== -1) {
            obj = item;
            throw Error();
          }
        }
      });
    } catch (e) {
      console.log(e);
    }
  }
  getUrl(arrlist, url);
  return obj;
}

// export function fun(arrlist, url) {
//     let obj = {}
//     for (let i = 0; i < arrlist.length; i++) {
//         let item = arrlist[i].items;
//         let newArr = arrlist[i].url.split("/")
//         if (newArr[1] === url) {
//             obj = arrlist[i]
//             return obj
//         } else {
//             obj = fun(item, url)
//             if (obj) return obj
//         }
//     }
// }
export function handleStatu(num) {
  switch (num) {
    case 1:
      return "未开始"; //若变量与变量值1相同则执行该处代码
    case 2:
      return "进行中"; //若变量与变量值2相同则执行该处代码
    case 3:
      return "已完成"; //若变量与变量值3相同则执行该处代码
    case 4:
      return "暂停中"; //若变量与变量值3相同则执行该处代码
  }
}
export function handleWeeks(str) {
  return str.split(",");
}
export function date_format(micro_second) {
  // 总秒数
  var second = Math.floor(micro_second / 1000);
  // 天数
  // var day = Math.floor(second / 3600 / 24);
  // 小时
  var hr = Math.floor((second / 3600) % 24);
  // 分钟
  var min = Math.floor((second / 60) % 60);
  // 秒
  var sec = Math.floor(second % 60);
  return hr + ":" + min + ":" + sec;
}
//Mark页面处理时间问题
export function formatDate(ts) {
  var now = new Date(parseInt(ts) * 1000);

  // console.log(now);

  var year = now.getFullYear();

  var month = ((now.getMonth() + 1) < 10) ? ('0' + (now.getMonth() + 1)) : (now.getMonth() + 1);

  var date = (now.getDate() < 10) ? ('0' + now.getDate()) : (now.getDate());

  var hour = (now.getHours() < 10) ? ('0' + now.getHours()) : (now.getHours());

  var minute = (now.getMinutes() < 10) ? ('0' + now.getMinutes()) : (now.getMinutes());

  var second = (now.getSeconds() < 10) ? ('0' + now.getSeconds()) : (now.getSeconds());

  return year + "-" + month + "-" + date + " " + hour + ":" + minute + ":" + second;

}
export function handleStatus(status) {
  switch (status) {
    case "1":
      return "审核中";
    case "2":
      return "审核通过";
    case "3":
      return "审核不通过";
    case "4":
      return "已取消";
  }
}
//对象转换成数组
export function obj_arr(obj) {
  let arr = [];
  for (let i in obj) {
    arr.push({
      key: i,
      value: obj[i],
    }); //属性
  }
  return arr;
}
export const hangupcauseFun = (str) => {
  switch (str) {
    case "NORMAL_CLEARING":
      return "呼叫成功";
    case "NORMAL_TEMPORARY_FAILURE":
      return "呼叫失败";
    case "NO_USER_RESPONSE":
      return "响应超时";
    case "RECOVERY_ON_TIMER_EXPIRE":
      return "网关超时";
    case "UNALLOCATED_NUMBER":
      return "未分配的号码";
    case "USER_BUSY":
      return "线路忙";
    case "CALL_REJECTED":
      return "呼叫拒绝，路由配置错误或者设备配置错误";
    case "USER_NOT_REGISTERED":
      return "没有注册";
    case "ORIGINATOR_CANCEL":
      return "发起人取消";
    case "NO_ANSWER":
      return "没有回应";
    default:
      return "呼叫失败"
  }
}
export const handleType = (num) => {
  switch (num) {
    case 2:
      return "A";
    case 3:
      return "B";
    case 4:
      return "C";
    case 5:
      return "D";
    case 6:
      return "E";
    case 7:
      return "F";
  }
}
export const sendState = (num) => {
  switch (num) {
    case '0':
      return "待发";
    case '1':
      return "成功";
    case '2':
      return "失败";
    case '3':
      return "未知";
  }
}
//获取当前时间和前一个月
export function formDate(time) {
  var now = new Date(time);
  // console.log(now);
  var year = now.getFullYear();

  var month = ((now.getMonth() + 1) < 10) ? ('0' + (now.getMonth() + 1)) : (now.getMonth() + 1);

  var date = (now.getDate() < 10) ? ('0' + now.getDate()) : (now.getDate());

  return year + "-" + month + "-" + date;

}
//账单类型
export function reasonType(str) {
  switch (str) {
    case 'open':
      return "开户";
    case 'update':
      return "升级";
    case 'renew':
      return "续费";
    case 'buy':
      return "购买";
  }
}
//支付方式
export function payType(str) {
  switch (str) {
    case 'weixin':
      return "微信";
    case 'alipay':
      return "支付宝";
    case 'yeepay':
      return "易宝";
    case 'manual':
      return "人工录入";
    case 'init':
      return "注册之后的初始金额";
  }
}
//支付状态
export function payState(str) {
  switch (str) {
    case 'init':
      return "初始";
    case 'success':
      return "成功";
    case 'cancel':
      return "取消";
    case 'fail':
      return "失败";
    case 'finish':
      return "完成";
  }
}
