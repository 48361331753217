<template>
  <a-layout style="min-height: 100vh">
    <a-layout-sider v-model:collapsed="collapsed" collapsible>
      <div class="logo" v-if="!collapsed">AI智能机器人管理系统</div>
      <div class="img" v-else>AI</div>
      <a-menu
        theme="light"
        v-model:selectedKeys="selectedKeys"
        :openKeys="openKeys"
        @openChange="onOpenChange"
        mode="inline"
        @select="handleSelect"
      >
        <a-menu-item key="zhuye">
          <HomeOutlined />
          <span>首页</span>
        </a-menu-item>
        <template :key="item.id" v-for="item in list">
          <!-- <a-menu-item v-if="item.items.length === 0" :key="item.name">
             <template #title>
              <span>
                <icon-font :type="item.icon"></icon-font>
                <span>{{ item.name }}</span>
              </span>
            </template>
          </a-menu-item> -->
          <!-- <a-menu-item key="zhuye">
            <HomeOutlined />
            <span>首页</span>
          </a-menu-item> -->
          <a-menu-item
            v-if="item.items.length === 0"
            :key="item.url + '$' + item.name"
          >
            <icon-font :type="item.icon"></icon-font>
            <span>{{ item.name }}</span>
          </a-menu-item>
          <a-sub-menu v-else :key="item.name">
            <template #title>
              <span>
                <icon-font :type="item.icon"></icon-font>
                <span>{{ item.name }}</span>
              </span>
            </template>
            <template v-for="item2 in item.items" :key="item2.id">
              <a-menu-item
                v-if="item2.items.length === 0"
                :key="item2.url + '$' + item2.name"
              >
                <span>{{ item2.name }}</span>
              </a-menu-item>
              <a-sub-menu v-else :key="item2.name">
                <template #title>
                  <span>{{ item2.name }}</span>
                </template>
                <a-menu-item
                  :key="item3.url + '$' + item3.name"
                  v-for="item3 in item2.items"
                  >{{ item3.name }}</a-menu-item
                >
              </a-sub-menu>
            </template>
          </a-sub-menu>
        </template>
      </a-menu>
    </a-layout-sider>
    <a-layout>
      <a-layout-header style="background: #fff; margin: 0 16px">
        <div class="left">{{ title }}</div>
        <div class="right">
          <img src="../assets/avator.jpg" />
          <a-dropdown>
            <a class="ant-dropdown-link" @click.prevent>
              {{ userInfo.username }}
              <DownOutlined />
            </a>
            <template #overlay>
              <a-menu @click="handleMenuClick">
                <a-menu-item key="1">
                  <a href="javascript:;">用户信息</a>
                </a-menu-item>
                <a-menu-item key="2">
                  <a href="javascript:;">修改密码</a>
                </a-menu-item>
                <!-- <a-menu-item key="3">
                  <a href="javascript:;">消息中心</a>
                </a-menu-item> -->
                <a-menu-item key="4">
                  <a href="javascript:;">退出系统</a>
                </a-menu-item>
              </a-menu>
            </template>
          </a-dropdown>
        </div>
      </a-layout-header>
      <a-layout-content style="margin: 16px">
        <router-view></router-view>
      </a-layout-content>
    </a-layout>
  </a-layout>
</template>
<script>
import { HomeOutlined, DownOutlined } from "@ant-design/icons-vue";
import { defineComponent, onMounted, reactive, ref, toRefs, watch } from "vue";
import { createFromIconfontCN } from "@ant-design/icons-vue";
import { getUser, getUserInfo } from "../service/home";
import { handleMenu } from "../utils/function";
import { useRouter, useRoute } from "vue-router";
const IconFont = createFromIconfontCN({
  scriptUrl: "//at.alicdn.com/t/font_2613152_k91f2y7pqr.js",
});
export default defineComponent({
  components: {
    HomeOutlined,
    DownOutlined,
    IconFont,
  },

  setup() {
    const router = useRouter();
    const route = useRoute();
    const collapsed = ref(false);
    const state = reactive({
      selectedKeys: [],
      list: [],
      openKeys: [],
      rootSubmenuKeys: [],
      title: "",
      userInfo: {},
      // username: undefined,
    });
    // state.username = route.query.username;
    onMounted(async () => {
      const token = sessionStorage.getItem("token");
      const params = {
        access_token: token,
      };
      const res = await getUser(params);
      const res2 = await getUserInfo(params);
      state.userInfo = res2.data.data;
      // console.log(res);
      console.log(res2);
      state.list = res.data.data;
      state.rootSubmenuKeys = res.data.data.map((item) => item.name);
      console.log(state.rootSubmenuKeys);
      routerChange(router.currentRoute._value.fullPath);
    });
    const onOpenChange = (openKeys) => {
      const latestOpenKey = openKeys.find(
        (key) => state.openKeys.indexOf(key) === -1
      );

      if (state.rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
        state.openKeys = openKeys;
      } else {
        state.openKeys = latestOpenKey ? [latestOpenKey] : [];
      }
    };
    watch(
      () => route.path,
      (newVal) => {
        // console.log(newVal, oldVal);
        // console.log(typeof newVal);
        routerChange(newVal);
      }
    );
    const routerChange = (newVal) => {
      if (newVal !== "/zhuye" && newVal !== "/") {
        let result = handleMenu(state.list, newVal);
        // console.log(result);
        state.title = result.name;
        state.selectedKeys = [result.url + "$" + result.name];
      } else {
        state.title = "首页";
        state.selectedKeys = ["zhuye"];
      }
    };
    let handleSelect = (e) => {
      // console.log(e);
      if (e.key == "zhuye") {
        state.title = "首页";
        router.push({ path: "/" });
      } else {
        let arr = e.key.split("$");
        state.title = arr[1];
        // console.log(arr[0])
        router.push({ path: arr[0] });
      }
    };
    let handleMenuClick = (e) => {
      console.log(e.key);
      switch (e.key) {
        case "1":
          router.push({ path: "/basic_update" });
          break;
        case "2":
          router.push({ path: "/basic_update" });
          break;
        case "4":
          sessionStorage.removeItem("token");
          router.push({ path: "/login" });
          break;
      }
    };
    return {
      collapsed,
      ...toRefs(state),
      onOpenChange,
      handleSelect,
      handleMenuClick,
    };
  },
});
</script>
<style>
.ant-layout-sider,
.ant-layout-sider-trigger {
  background-color: #fff !important;
  color: #000 !important;
}
</style>
<style scoped>
.logo {
  height: 44px;
  line-height: 44px;
  font-size: 18px;
  text-align: center;
}
.img {
  height: 40px;
  line-height: 40px;
  width: 100%;
  text-align: center;
  font-size: 18px;
  font-weight: bold;
}
#components-layout-demo-side .logo {
  height: 32px;
  margin: 16px;
  background: rgba(255, 255, 255, 0.3);
}
.site-layout .site-layout-background {
  background: #fff;
}

.ant-layout-header {
  height: 44px !important;
  line-height: 44px !important;
  padding-left: 15px !important;
  padding-right: 15px !important;
  font-size: 16px;
  display: flex;
  justify-content: space-between;
}
.ant-layout-header img {
  width: 25px;
  border-radius: 50%;
  margin-right: 10px;
}
a {
  color: #000 !important;
}
.ant-dropdown-link {
  color: #1890ff !important;
}
.ant-menu-inline {
  border-right: none !important;
}
.ant-menu-root {
  height: calc(100vh - 92px);
  overflow-y: auto;
  overflow-x: hidden;
}
.ant-menu-root::-webkit-scrollbar {
  display: none; /* Chrome Safari */
}

.ant-menu-root {
  scrollbar-width: none; /* firefox */

  -ms-overflow-style: none; /* IE 10+ */

  overflow-x: hidden;
  overflow-y: auto;
}
.ant-layout-content {
  height: calc(100vh - 76px);
  overflow-y: auto;
}
</style>
